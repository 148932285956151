export const perfectFitWidthxDepthColumnData = [
  "300x300",
  "300x450",
  "300x600",
  "300x750",
  "300x900",
  "300x1050",
  "300x1200",
  "300x1350",
  "300x1500",
  "300x1650",
  "300x1800",
  "300x1950",
  "300x2100",
  "300x2250",
  "300x2400",
  "450x300",
  "450x450",
  "450x600",
  "450x750",
  "450x900",
  "450x1050",
  "450x1200",
  "450x1350",
  "450x1500",
  "450x1650",
  "450x1800",
  "450x1950",
  "450x2100",
  "450x2250",
  "450x2400",
  "600x300",
  "600x450",
  "600x600",
  "600x750",
  "600x900",
  "600x1050",
  "600x1200",
  "600x1350",
  "600x1500",
  "600x1650",
  "600x1800",
  "600x1950",
  "600x2100",
  "600x2250",
  "600x2400",
  "750x300",
  "750x450",
  "750x600",
  "750x750",
  "750x900",
  "750x1050",
  "750x1200",
  "750x1350",
  "750x1500",
  "750x1650",
  "750x1800",
  "750x1950",
  "750x2100",
  "750x2250",
  "750x2400",
  "900x300",
  "900x450",
  "900x600",
  "900x750",
  "900x900",
  "900x1050",
  "900x1200",
  "900x1350",
  "900x1500",
  "900x1650",
  "900x1800",
  "900x1950",
  "900x2100",
  "900x2250",
  "900x2400",
  "1050x300",
  "1050x450",
  "1050x600",
  "1050x750",
  "1050x900",
  "1050x1050",
  "1050x1200",
  "1050x1350",
  "1050x1500",
  "1050x1650",
  "1050x1800",
  "1050x1950",
  "1050x2100",
  "1050x2250",
  "1050x2400",
  "1200x300",
  "1200x450",
  "1200x600",
  "1200x750",
  "1200x900",
  "1200x1050",
  "1200x1200",
  "1200x1350",
  "1200x1500",
  "1200x1650",
  "1200x1800",
  "1200x1950",
  "1200x2100",
  "1200x2250",
  "1200x2400",
  "1350x300",
  "1350x450",
  "1350x600",
  "1350x750",
  "1350x900",
  "1350x1050",
  "1350x1200",
  "1350x1350",
  "1350x1500",
  "1350x1650",
  "1350x1800",
  "1350x1950",
  "1350x2100",
  "1350x2250",
  "1350x2400",
  "1500x300",
  "1500x450",
  "1500x600",
  "1500x750",
  "1500x900",
  "1500x1050",
  "1500x1200",
  "1500x1350",
  "1500x1500",
  "1500x1650",
  "1500x1800",
  "1500x1950",
  "1500x2100",
  "1500x2250",
  "1500x2400",
  "1650x300",
  "1650x450",
  "1650x600",
  "1650x750",
  "1650x900",
  "1650x1050",
  "1650x1200",
  "1650x1350",
  "1650x1500",
  "1650x1650",
  "1650x1800",
  "1650x1950",
  "1650x2100",
  "1650x2250",
  "1650x2400",
  "1800x300",
  "1800x450",
  "1800x600",
  "1800x750",
  "1800x900",
  "1800x1050",
  "1800x1200",
  "1800x1350",
  "1800x1500",
  "1800x1650",
  "1800x1800",
  "1800x1950",
  "1800x2100",
  "1800x2250",
  "1800x2400",
  "1950x300",
  "1950x450",
  "1950x600",
  "1950x750",
  "1950x900",
  "1950x1050",
  "1950x1200",
  "1950x1350",
  "1950x1500",
  "1950x1650",
  "1950x1800",
  "1950x1950",
  "1950x2100",
  "1950x2250",
  "1950x2400",
  "2100x300",
  "2100x450",
  "2100x600",
  "2100x750",
  "2100x900",
  "2100x1050",
  "2100x1200",
  "2100x1350",
  "2100x1500",
  "2100x1650",
  "2100x1800",
  "2100x1950",
  "2100x2100",
  "2100x2250",
  "2100x2400",
  "2250x300",
  "2250x450",
  "2250x600",
  "2250x750",
  "2250x900",
  "2250x1050",
  "2250x1200",
  "2250x1350",
  "2250x1500",
  "2250x1650",
  "2250x1800",
  "2250x1950",
  "2250x2100",
  "2250x2250",
  "2250x2400",
  "2400x300",
  "2400x450",
  "2400x600",
  "2400x750",
  "2400x900",
  "2400x1050",
  "2400x1200",
  "2400x1350",
  "2400x1500",
  "2400x1650",
  "2400x1800",
  "2400x1950",
  "2400x2100",
  "2400x2250",
  "2400x2400",
];

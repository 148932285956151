export const conservatoryRoofsWidth = [
  "2500",
  "3000",
  "3500",
  "4000",
  "4500",
  "5000",
  "5500",
  "6000",
  "6500",
  "7000",
  "7500",
  "8000",
  "8500",
  "9000",
];

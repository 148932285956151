export const lanternWidthxDepthColumnData =[
    "300x300",
"300x450",
"300x600",
"300x750",
"300x900",
"300x1050",
"300x1200",
"300x1350",
"300x1500",
"300x1650",
"300x1800",
"300x1950",
"300x2100",
"300x2250",
"300x2400",
"300x2550",
"300x2700",
"300x2850",
"300x3000",
"300x3150",
"300x3300",
"300x3450",
"300x3600",
"300x3750",
"300x3900",
"300x4050",
"450x300",
"450x450",
"450x600",
"450x750",
"450x900",
"450x1050",
"450x1200",
"450x1350",
"450x1500",
"450x1650",
"450x1800",
"450x1950",
"450x2100",
"450x2250",
"450x2400",
"450x2550",
"450x2700",
"450x2850",
"450x3000",
"450x3150",
"450x3300",
"450x3450",
"450x3600",
"450x3750",
"450x3900",
"450x4050",
"600x300",
"600x450",
"600x600",
"600x750",
"600x900",
"600x1050",
"600x1200",
"600x1350",
"600x1500",
"600x1650",
"600x1800",
"600x1950",
"600x2100",
"600x2250",
"600x2400",
"600x2550",
"600x2700",
"600x2850",
"600x3000",
"600x3150",
"600x3300",
"600x3450",
"600x3600",
"600x3750",
"600x3900",
"600x4050",
"750x300",
"750x450",
"750x600",
"750x750",
"750x900",
"750x1050",
"750x1200",
"750x1350",
"750x1500",
"750x1650",
"750x1800",
"750x1950",
"750x2100",
"750x2250",
"750x2400",
"750x2550",
"750x2700",
"750x2850",
"750x3000",
"750x3150",
"750x3300",
"750x3450",
"750x3600",
"750x3750",
"750x3900",
"750x4050",
"900x300",
"900x450",
"900x600",
"900x750",
"900x900",
"900x1050",
"900x1200",
"900x1350",
"900x1500",
"900x1650",
"900x1800",
"900x1950",
"900x2100",
"900x2250",
"900x2400",
"900x2550",
"900x2700",
"900x2850",
"900x3000",
"900x3150",
"900x3300",
"900x3450",
"900x3600",
"900x3750",
"900x3900",
"900x4050",
"1050x300",
"1050x450",
"1050x600",
"1050x750",
"1050x900",
"1050x1050",
"1050x1200",
"1050x1350",
"1050x1500",
"1050x1650",
"1050x1800",
"1050x1950",
"1050x2100",
"1050x2250",
"1050x2400",
"1050x2550",
"1050x2700",
"1050x2850",
"1050x3000",
"1050x3150",
"1050x3300",
"1050x3450",
"1050x3600",
"1050x3750",
"1050x3900",
"1050x4050",
"1200x300",
"1200x450",
"1200x600",
"1200x750",
"1200x900",
"1200x1050",
"1200x1200",
"1200x1350",
"1200x1500",
"1200x1650",
"1200x1800",
"1200x1950",
"1200x2100",
"1200x2250",
"1200x2400",
"1200x2550",
"1200x2700",
"1200x2850",
"1200x3000",
"1200x3150",
"1200x3300",
"1200x3450",
"1200x3600",
"1200x3750",
"1200x3900",
"1200x4050",
"1350x300",
"1350x450",
"1350x600",
"1350x750",
"1350x900",
"1350x1050",
"1350x1200",
"1350x1350",
"1350x1500",
"1350x1650",
"1350x1800",
"1350x1950",
"1350x2100",
"1350x2250",
"1350x2400",
"1350x2550",
"1350x2700",
"1350x2850",
"1350x3000",
"1350x3150",
"1350x3300",
"1350x3450",
"1350x3600",
"1350x3750",
"1350x3900",
"1350x4050",
"1500x300",
"1500x450",
"1500x600",
"1500x750",
"1500x900",
"1500x1050",
"1500x1200",
"1500x1350",
"1500x1500",
"1500x1650",
"1500x1800",
"1500x1950",
"1500x2100",
"1500x2250",
"1500x2400",
"1500x2550",
"1500x2700",
"1500x2850",
"1500x3000",
"1500x3150",
"1500x3300",
"1500x3450",
"1500x3600",
"1500x3750",
"1500x3900",
"1500x4050",
"1650x300",
"1650x450",
"1650x600",
"1650x750",
"1650x900",
"1650x1050",
"1650x1200",
"1650x1350",
"1650x1500",
"1650x1650",
"1650x1800",
"1650x1950",
"1650x2100",
"1650x2250",
"1650x2400",
"1650x2550",
"1650x2700",
"1650x2850",
"1650x3000",
"1650x3150",
"1650x3300",
"1650x3450",
"1650x3600",
"1650x3750",
"1650x3900",
"1650x4050",
"1800x300",
"1800x450",
"1800x600",
"1800x750",
"1800x900",
"1800x1050",
"1800x1200",
"1800x1350",
"1800x1500",
"1800x1650",
"1800x1800",
"1800x1950",
"1800x2100",
"1800x2250",
"1800x2400",
"1800x2550",
"1800x2700",
"1800x2850",
"1800x3000",
"1800x3150",
"1800x3300",
"1800x3450",
"1800x3600",
"1800x3750",
"1800x3900",
"1800x4050",
"1950x300",
"1950x450",
"1950x600",
"1950x750",
"1950x900",
"1950x1050",
"1950x1200",
"1950x1350",
"1950x1500",
"1950x1650",
"1950x1800",
"1950x1950",
"1950x2100",
"1950x2250",
"1950x2400",
"1950x2550",
"1950x2700",
"1950x2850",
"1950x3000",
"1950x3150",
"1950x3300",
"1950x3450",
"1950x3600",
"1950x3750",
"1950x3900",
"1950x4050",
"2100x300",
"2100x450",
"2100x600",
"2100x750",
"2100x900",
"2100x1050",
"2100x1200",
"2100x1350",
"2100x1500",
"2100x1650",
"2100x1800",
"2100x1950",
"2100x2100",
"2100x2250",
"2100x2400",
"2100x2550",
"2100x2700",
"2100x2850",
"2100x3000",
"2100x3150",
"2100x3300",
"2100x3450",
"2100x3600",
"2100x3750",
"2100x3900",
"2100x4050",
"2250x300",
"2250x450",
"2250x600",
"2250x750",
"2250x900",
"2250x1050",
"2250x1200",
"2250x1350",
"2250x1500",
"2250x1650",
"2250x1800",
"2250x1950",
"2250x2100",
"2250x2250",
"2250x2400",
"2250x2550",
"2250x2700",
"2250x2850",
"2250x3000",
"2250x3150",
"2250x3300",
"2250x3450",
"2250x3600",
"2250x3750",
"2250x3900",
"2250x4050",
"2400x300",
"2400x450",
"2400x600",
"2400x750",
"2400x900",
"2400x1050",
"2400x1200",
"2400x1350",
"2400x1500",
"2400x1650",
"2400x1800",
"2400x1950",
"2400x2100",
"2400x2250",
"2400x2400",
"2400x2550",
"2400x2700",
"2400x2850",
"2400x3000",
"2400x3150",
"2400x3300",
"2400x3450",
"2400x3600",
"2400x3750",
"2400x3900",
"2400x4050",
"2550x300",
"2550x450",
"2550x600",
"2550x750",
"2550x900",
"2550x1050",
"2550x1200",
"2550x1350",
"2550x1500",
"2550x1650",
"2550x1800",
"2550x1950",
"2550x2100",
"2550x2250",
"2550x2400",
"2550x2550",
"2550x2700",
"2550x2850",
"2550x3000",
"2550x3150",
"2550x3300",
"2550x3450",
"2550x3600",
"2550x3750",
"2550x3900",
"2550x4050",
"2700x300",
"2700x450",
"2700x600",
"2700x750",
"2700x900",
"2700x1050",
"2700x1200",
"2700x1350",
"2700x1500",
"2700x1650",
"2700x1800",
"2700x1950",
"2700x2100",
"2700x2250",
"2700x2400",
"2700x2550",
"2700x2700",
"2700x2850",
"2700x3000",
"2700x3150",
"2700x3300",
"2700x3450",
"2700x3600",
"2700x3750",
"2700x3900",
"2700x4050",
"2850x300",
"2850x450",
"2850x600",
"2850x750",
"2850x900",
"2850x1050",
"2850x1200",
"2850x1350",
"2850x1500",
"2850x1650",
"2850x1800",
"2850x1950",
"2850x2100",
"2850x2250",
"2850x2400",
"2850x2550",
"2850x2700",
"2850x2850",
"2850x3000",
"2850x3150",
"2850x3300",
"2850x3450",
"2850x3600",
"2850x3750",
"2850x3900",
"2850x4050",
"3000x300",
"3000x450",
"3000x600",
"3000x750",
"3000x900",
"3000x1050",
"3000x1200",
"3000x1350",
"3000x1500",
"3000x1650",
"3000x1800",
"3000x1950",
"3000x2100",
"3000x2250",
"3000x2400",
"3000x2550",
"3000x2700",
"3000x2850",
"3000x3000",
"3000x3150",
"3000x3300",
"3000x3450",
"3000x3600",
"3000x3750",
"3000x3900",
"3000x4050",
] 
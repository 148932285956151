export const perfectFitVenetianFabricTypes = [
  "Alpine",
  "American Elm",
  "Antique Silver",
  "Ash",
  "Barely Black",
  "Black Gloss",
  "Blue Haze",
  "Brick",
  "Bronze",
  "Brushed Copper",
  "Brushed Nickel",
  "Brushed Silver",
  "Buttercup",
  "Calico",
  "Caramel",
  "Chalk White",
  "Champagne",
  "Charcoal",
  "Cherry",
  "Chestnut",
  "Classic Stripe",
  "Classic White",
  "Conker",
  "Cool Aqua",
  "Cool Sky",
  "Copper",
  "Creamy Apricot",
  "Creamy Apricot",
  "Damson",
  "Deep Ivy",
  "Dorothy",
  "Dusty Grey",
  "Elderberry",
  "Electric Aqua",
  "Everglades",
  "Fern Green",
  "Filtra Black",
  "Filtra Magnolia",
  "Filtra Silver",
  "Filtra White",
  "Fresh Apple",
  "Frosted Silver",
  "Fruitwood",
  "Fuchsia",
  "Glacier",
  "Glistening Gold",
  "Gloss White",
  "Gold Copper",
  "Gold Yellow",
  "Graphite",
  "Harvest",
  "Hessian",
  "Honey",
  "Hornbeam",
  "Ice Blue",
  "Ice Pink",
  "Indian Yellow",
  "Ivory",
  "Jet",
  "Kharki",
  "Latte",
  "Magnolia",
  "Mahogany",
  "Marigold",
  "Melba",
  "Mellow Peach",
  "Melon",
  "Mercury",
  "Mid Grey",
  "Midnight Blue",
  "Mint Cream",
  "Mocha",
  "Mushroom",
  "Natural Taupe",
  "Oatmeal",
  "Off White",
  "Orange",
  "Oriental Blue",
  "Oxford Blue",
  "Oyster White",
  "Peppermint",
  "Pink Blossom",
  "Pinstripe",
  "Pistachio",
  "Pitch Black",
  "Polar",
  "Praline",
  "Pretty Pink",
  "Russet",
  "Sand",
  "Sapphire",
  "Satin Silver",
  "Scarlet",
  "Scottish Pine",
  "Sea Blue",
  "Shiny Silver",
  "Silver",
  "Silver Lustre",
  "Silver Pitch",
  "Snowflake",
  "Soft Brown ",
  "Soft Orange",
  "Soft Stone",
  "Sparkle Black",
  "Sparkle Blue",
  "Spring Blush",
  "Sugar Maple",
  "Sugar Pink",
  "Sunrise",
  "Twilight Grey",
  "Walnut",
  "White",
  "White Shimmer",
  "Wild Plum",
];

export const powerOptions = [
  "12v AC/DC Power Supply",
  "12v Mains Power",
  "Large Battery",
  "Li-ion Battery Pack",
  "Power 1.5 DC",
  "Power 2.7 DC",
  "Power 4.5 DC",
  "Small Battery",
  "Solar Panel",
];

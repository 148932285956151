export const commonWidths = [
  "300",
  "450",
  "600",
  "750",
  "900",
  "1050",
  "1200",
  "1350",
  "1500",
  "1650",
  "1800",
  "1950",
  "2100",
  "2250",
  "2400",
  "2550",
  "2700",
  "2850",
  "3000",
];

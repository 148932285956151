export const commonFabricTypes = [
  "Arevelo (Width: 2100mm)",
  "Argan asc ECO (Width: 2000mm)",
  "Arianna (Width: 2200mm)",
  "Atlanta FR (Width: 3000mm)",
  "Aura (Width: 2050mm)",
  "Bali (Width: 2250mm)",
  "Baltimore (Width: 3000mm)",
  "Basix (Width: 2050mm)",
  "Beatrice (Width: 2100mm)",
  "Burlington (Width: 3000mm)",
  "Burlington Blackout (Width: 3000mm)",
  "Cactus asc ECO (Width: 2000mm)",
  "Calia ASC FR (Width: 2100mm)",
  "Capella (Width: 2400mm)",
  "Chenille ASC (Width: 2000mm)",
  "Cherry Blossom ESP BO (Width: 2050mm)",
  "Creped (Width: 2200mm)",
  "Daze (Width: 1780mm)",
  "Duopleat (Width: 4500mm)",
  "Duopleat Blackout (Width: 4500mm)",
  "Echo (Width: 2000mm)",
  "Edmonton (Width: 2500mm)",
  "Fairhaven (Width: 2000mm)",
  "Festival ESP (Width: 2050mm)",
  "Festival SPC (Width: 2100mm)",
  "Florence (Width: 2100mm)",
  "Flux (Width: 1780mm)",
  "Halifax (Width: 3000mm)",
  "Halifax Blackout (Width: 3000mm)",
  "Halo (Width: 3000mm)",
  "Halo Blackout (Width: 2100mm)",
  "Hampton (Width: 2000mm)",
  "Hemp asc ECO (Width: 2000mm)",
  "Henley Stripe (Width: 2300mm)",
  "Herringbone (Width: 2050mm)",
  "Hive Blackout (Width: 2250mm)",
  "Hive Blackout FR (Width: 2250mm)",
  "Hive Decadence (Width: 2200mm)",
  "Hive Deluxe (Width: 2250mm)",
  "Hive Deluxe Blackout (Width: 2250mm)",
  "Hive Dolce (Width: 2200mm)",
  "Hive Envy (Width: 2200mm)",
  "Hive Gratia (Width: 2250mm)",
  "Hive Idole (Width: 2200mm)",
  "Hive Lusso (Width: 2300mm)",
  "Hive Matrix (Width: 2250mm)",
  "Hive Matrix Blackout (Width: 2250mm)",
  "Hive Micro (Width: 1500mm)",
  "Hive Muse (Width: 2200mm)",
  "Hive Nirvana (Width: 2200mm)",
  "Hive Plain (Width: 2250mm)",
  "Hive Plain FR (Width: 2250mm)",
  "Hive Silkweave (Width: 2250mm)",
  "Hive Silkweave Blackout (Width: 2250mm)",
  "Hive Telia (Width: 2250mm)",
  "Hopscotch (Width: 2100mm)",
  "Hudson (Width: 3000mm)",
  "Iconic asc (Width: 1780mm)",
  "Infusion (Width: 1800mm)",
  "Infusion ASC (Width: 1800mm)",
  "Infusion asc FR ECO (Width: 1800mm)",
  "Infusion ASC Micro (Width: 1800mm)",
  "Leora (Width: 2100mm)",
  "Lina (Width: 1830mm)",
  "Linara SPC (Width: 2100mm)",
  "Luna BO (Width: 3000mm)",
  "Margurite (Width: 2000mm)",
  "Meadow (Width: 2000mm)",
  "Meadow Flower ASC (Width: 1800mm)",
  "Metropol (Width: 2000mm)",
  "Mineral ASC (Width: 2000mm)",
  "Oklahoma (Width: 2500mm)",
  "Oklahoma Blackout (Width: 2500mm)",
  "Olympia (Width: 2000mm)",
  "Orbit (Width: 2000mm)",
  "Origin (Width: 3000mm)",
  "Origin Blackout (Width: 3000mm)",
  "Palma (Width: 3000mm)",
  "Palma Blackout (Width: 3000mm)",
  "Parchament SPC (Width: 2100mm)",
  "Paris (Width: 2400mm)",
  "Patina (Width: 2000mm)",
  "Perla FR asc (Width: 2290mm)",
  "Pittsburgh (Width: 3000mm)",
  "Pittsburgh Blackout (Width: 3000mm)",
  "Pittsburgh Micro (Width: 3000mm)",
  "Pittsburgh Micro Blackout (Width: 3000mm)",
  "Radiance asc (Width: 2000mm)",
  "Radiance asc Micro (Width: 2000mm)",
  "Regency SPC (Width: 2000mm)",
  "Relife (Width: 2400mm)",
  "Restore (Width: 2400mm)",
  "Ribbons ASC (Width: 2000mm)",
  "Ribbons ASC Micro (Width: 2000mm)",
  "Ritz (Width: 2050mm)",
  "Sakura (Width: 2400mm)",
  "Santiago (Width: 2000mm)",
  "Serene (Width: 2380mm)",
  "Shadow Blackout (Width: 2350mm)",
  "Shea asc ECO (Width: 2000mm)",
  "Shimmer Dots (Width: 1780mm)",
  "Shine (Width: 2000mm)",
  "Shot Silk ESP BO (Width: 2050mm)",
  "Strata SPC (Width: 2050mm)",
  "Stratford (Width: 2100mm)",
  "Substance (Width: 1780mm)",
  "Tobias (Width: 2200mm)",
  "Topaz ESP BO (Width: 2050mm)",
  "Vita asc (Width: 1780mm)",
  "Voile (Width: 2000mm)",
  "Wilton Blackout (Width: 2100mm)",
  "Woodbark ASC (Width: 2000mm)",
];

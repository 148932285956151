export const perfectFitVenetianDepth = [
  "610",
  "760",
  "910",
  "1060",
  "1210",
  "1360",
  "1510",
  "1660",
  "1810",
  "1960",
  "2110",
  "2260",
];

export const perfectFitVenetianWidthxDepthColumnData = [
    "460x610",
"460x760",
"460x910",
"460x1060",
"460x1210",
"460x1360",
"460x1510",
"460x1660",
"460x1810",
"460x1960",
"460x2110",
"460x2260",
"610x610",
"610x760",
"610x910",
"610x1060",
"610x1210",
"610x1360",
"610x1510",
"610x1660",
"610x1810",
"610x1960",
"610x2110",
"610x2260",
"760x610",
"760x760",
"760x910",
"760x1060",
"760x1210",
"760x1360",
"760x1510",
"760x1660",
"760x1810",
"760x1960",
"760x2110",
"760x2260",
"910x610",
"910x760",
"910x910",
"910x1060",
"910x1210",
"910x1360",
"910x1510",
"910x1660",
"910x1810",
"910x1960",
"910x2110",
"910x2260",
"1060x610",
"1060x760",
"1060x910",
"1060x1060",
"1060x1210",
"1060x1360",
"1060x1510",
"1060x1660",
"1060x1810",
"1060x1960",
"1060x2110",
"1060x2260",
"1210x610",
"1210x760",
"1210x910",
"1210x1060",
"1210x1210",
"1210x1360",
"1210x1510",
"1210x1660",
"1210x1810",
"1210x1960",
"1210x2110",
"1210x2260",
"1300x610",
"1300x760",
"1300x910",
"1300x1060",
"1300x1210",
"1300x1360",
"1300x1510",
"1300x1660",
"1300x1810",
"1300x1960",
"1300x2110",
"1300x2260",
]
export const conservatoryWidthxDepthColumnData = [
    "2500x2500",
"2500x3000",
"2500x3500",
"2500x4000",
"2500x4500",
"2500x5000",
"2500x5500",
"2500x6000",
"3000x2500",
"3000x3000",
"3000x3500",
"3000x4000",
"3000x4500",
"3000x5000",
"3000x5500",
"3000x6000",
"3500x2500",
"3500x3000",
"3500x3500",
"3500x4000",
"3500x4500",
"3500x5000",
"3500x5500",
"3500x6000",
"4000x2500",
"4000x3000",
"4000x3500",
"4000x4000",
"4000x4500",
"4000x5000",
"4000x5500",
"4000x6000",
"4500x2500",
"4500x3000",
"4500x3500",
"4500x4000",
"4500x4500",
"4500x5000",
"4500x5500",
"4500x6000",
"5000x2500",
"5000x3000",
"5000x3500",
"5000x4000",
"5000x4500",
"5000x5000",
"5000x5500",
"5000x6000",
"5500x2500",
"5500x3000",
"5500x3500",
"5500x4000",
"5500x4500",
"5500x5000",
"5500x5500",
"5500x6000",
"6000x2500",
"6000x3000",
"6000x3500",
"6000x4000",
"6000x4500",
"6000x5000",
"6000x5500",
"6000x6000",
"6500x2500",
"6500x3000",
"6500x3500",
"6500x4000",
"6500x4500",
"6500x5000",
"6500x5500",
"6500x6000",
"7000x2500",
"7000x3000",
"7000x3500",
"7000x4000",
"7000x4500",
"7000x5000",
"7000x5500",
"7000x6000",
"7500x2500",
"7500x3000",
"7500x3500",
"7500x4000",
"7500x4500",
"7500x5000",
"7500x5500",
"7500x6000",
"8000x2500",
"8000x3000",
"8000x3500",
"8000x4000",
"8000x4500",
"8000x5000",
"8000x5500",
"8000x6000",
"8500x2500",
"8500x3000",
"8500x3500",
"8500x4000",
"8500x4500",
"8500x5000",
"8500x5500",
"8500x6000",
"9000x2500",
"9000x3000",
"9000x3500",
"9000x4000",
"9000x4500",
"9000x5000",
"9000x5500",
"9000x6000",
]